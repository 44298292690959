<template>
  <div>
    <div class="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image " data-bs-bg="../../../public/assets/img/pageTitle/uretim.png">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="ltn__breadcrumb-inner">
              <h1 class="page-title">{{ product.name }}</h1>
              <div class="ltn__breadcrumb-list">
                <ul>
                  <li><router-link to="anasayfa" ><span class="ltn__secondary-color"><i class="fas fa-home"></i></span> {{ langcontent.anasayfa }}</router-link></li>
                  <li>{{ product.name }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="select-availability-area pb-120">
      <div class="container">
        <div class="row">
          <FsLightbox
              :toggler="toggler"
              :sources="[product.detailtable]"
              type="image"
          />
          <div class="col-lg-6 col-md-8 col-sm-12">
            <div class="section-title-area ltn__section-title-2--- text-center---">
              <h6 class="section-subtitle section-subtitle-2--- ltn__secondary-color">{{ product.code }}</h6>
              <h1 class="section-title">{{ product.name }}</h1>
            </div>
            <div class="ltn__select-availability-table-wrap mb-3">
              {{ product.text }}
            </div>

            <button @click="toggler = !toggler">Teknik Detay</button>
          </div>
          <div class="col-lg-6 col-md-4 col-sm-12">
            <img :src="product.img" :alt="product.name">
          </div>
        </div>
      </div>
    </div>


  </div>
</template>
<style scoped>
.bg-overlay-white-30:before{
  background-image: url("../../../public/assets/img/pageTitle/pg.png");
  background-position-x: center;
}
</style>
<script>
import FsLightbox from "fslightbox-vue/v3";
import iso from "../../axios";
export default {
  props:['langcontent'],
  components: {
    FsLightbox
  },
  data() {
    return {
      toggler: false,
      product:[]
    }
  },
  created() {
    iso.get('product/'+this.$route.params.mId)
        .then(response => {
          this.product = response.data
        })
        .catch(error => {
          console.log(error);
        })
  },
  watch:{
    $route (to, from){
      iso.get('product/'+this.$route.params.mId)
          .then(response => {
            this.product = response.data
          })
          .catch(error => {
            console.log(error);
          })
    }
  },
  beforeRouteLeave (to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>